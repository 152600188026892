import { createRouter, createWebHistory } from "vue-router";
import App from "./App.vue";
import store from "./store";

const routes = [
  {
    path: "/dashboard",
    name: "app",
    component: App,
    meta: { requiresAuth: true }, // Menandakan bahwa rute ini memerlukan autentikasi
    children: [
      {
        path: "",
        name: "dashboard",
        component: () => import("./pages/Dashboard.vue"),
      },
      {
        path: "/project",
        name: "project",
        component: () => import("./pages/master/Project.vue"),
      },
      // {
      //   path: "/project/:id/outlet-type",
      //   name: "project-outlet-type",
      //   component: () =>
      //     import("./components/master/project/outlet-type/Index2.vue"),
      // },
      {
        path: "/project/:id/outlet",
        name: "project-outlet",
        component: () => import("./components/master/project/outlet/Index.vue"),
      },
      {
        path: "/project/:id/detail",
        name: "project-detail",
        component: () => import("./components/master/project/detail/Index.vue"),
        children: [
          {
            path: "/project/:id/detail/salesman-calculation",
            name: "project-detail-salesman-calculation",
            component: () =>
              import(
                "./components/master/project/detail/salesman-calculation/Index.vue"
              ),
          },
          {
            path: "/project/:id/detail/delivery-calculation",
            name: "project-detail-delivery-calculation",
            component: () =>
              import(
                "./components/master/project/detail/delivery-calculation/Index.vue"
              ),
          },
          {
            path: "/project/:id/detail/territory-mapping",
            name: "project-detail-territory-mapping",
            component: () =>
              import(
                "./components/master/project/detail/territory-mapping/Index.vue"
              ),
          },
          {
            path: "/project/:id/detail/territory-mapping/:clusterslot_id/by-numbers-of-beat",
            name: "project-detail-territory-mapping-by-numbers-of-beat",
            component: () =>
              import(
                "./components/master/project/detail/territory-mapping/beat/Index.vue"
              ),
          },
          {
            path: "/project/:id/detail/territory-mapping/:clusterslot_id/by-numbers-of-outlet",
            name: "project-detail-territory-mapping-by-numbers-of-outlet",
            component: () =>
              import(
                "./components/master/project/detail/territory-mapping/outlet/Index.vue"
              ),
          },
          {
            path: "/project/:id/detail/territory-mapping/:clusterslot_id/by-numbers-of-working-hours-per-day",
            name: "project-detail-territory-mapping-by-numbers-of-working-hours-per-day",
            component: () =>
              import(
                "./components/master/project/detail/territory-mapping/working-hours/Index.vue"
              ),
          },
        ],
      },
      {
        path: "/industry",
        name: "industry",
        component: () => import("./pages/master/Industry.vue"),
      },
      {
        path: "/outlet-type",
        name: "outlet-type",
        component: () => import("./pages/master/OutletType.vue"),
      },
      {
        path: "/example-page",
        name: "example-page",
        component: () => import("./pages/ExamplePage.vue"),
      },
    ],
  },
  {
    path: "/login",
    name: "login",
    component: () => import("./pages/Login.vue"),
  },
  {
    path: "/error",
    name: "error",
    component: () => import("./pages/Error.vue"),
  },
  {
    path: "/access",
    name: "access",
    component: () => import("./pages/Access.vue"),
  },
  {
    path: "/payment",
    name: "payment",
    component: () => import("./pages/Payment.vue"),
  },
  {
    path: "/",
    redirect: "/dashboard",
  },
  {
    path: "/:catchAll(.*)",
    name: "NotFound",
    component: () => import("./pages/NotFound.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const isAuthenticated = localStorage.getItem("authToken");

  if (to.name === "login" && isAuthenticated) {
    next({ name: "dashboard" });
  } else {
    // Mengecek apakah rute saat ini memiliki children
    if (to.matched.some((route) => route.meta.requiresAuth)) {
      // Jika pengguna telah login, lanjutkan ke rute yang diminta
      if (isAuthenticated) {
        store.dispatch("auth/getUserData");
        setTimeout(() => {
          if (localStorage.getItem("stts") == "active") {
            next();
          } else {
            next({ name: "payment" });
          }
        }, 250);
      } else {
        // Jika pengguna belum login, redirect ke halaman login
        next({ name: "login" });
      }
    } else {
      // Jika rute tidak memiliki children, lanjutkan ke rute yang diminta
      next();
    }
  }
});

export default router;
